$(function() {
    var BREAK_POINT = 768;

    // メニューのカレンと表示(PC)
    var $link = $('.js-menu-link');
    var path = location.pathname;

    $link.each(function() {
        var $this = $(this);

        if ($this.attr('href') === path) {
            $this.addClass('is-active');
        }
    });

    // メニューの表示トグル(SP)
    var $menuBtn = $('.js-menu-btn');
    var $menuArea = $('.js-menu-area');

    $menuBtn.on('click', function(e) {
        e.preventDefault();

        if ($(window).width() <= BREAK_POINT) {
            $menuArea.toggleClass('is-active');
        }
    });


    // フッタースライドメニュー(SP)
    var $slideBtn = $('.js-slide-btn');

    $slideBtn.on('click', function() {
        if ($(window).width() <= BREAK_POINT) {
            $(this).toggleClass('is-active').next().slideToggle();
        }
    });

    $.fn.dynamicStationForm = function (url) {
        var $selector = $(this);
        var $target = $('.js-dsf-line');

        $target.change(function() {
            var line_id = $(this).val();
            $.ajax({
                url: url,
                data: {line_id: line_id},
                type: 'GET',
                success: function(data) {
                    $selector.prop('disabled', true);
                    $selector.children('option:not(:first-child)').remove();
                    $.each(data.stations, function(val, key) {
                        var option = $('<option></option>').val(val).text(key);
                        $selector.append(option);
                    });
                    $selector.prop('disabled', false);
                }
            });
        });
        $('.js-dsf-reset').click(function() {
            $selector.children('option:not(:first-child)').remove();
        });
    };

    $.fn.dynamicMunicipalityForm = function (url) {
        var $selector = $(this);
        var $target = $('.js-dsf-prefectures');

        $target.on('change', function(e, municipality_id) {
            var prefecture_id = $(this).val();
            $.ajax({
                url: url,
                data: {prefecture_id: prefecture_id},
                type: 'GET',
                success: function(data) {
                    $selector.prop('disabled', true);
                    $selector.children('option:not(:first-child)').remove();
                    $.each(data.municipalities, function(val, key) {
                        var option = $('<option></option>').val(val).text(key);
                        if ( val == municipality_id ) {
                            option.prop('selected', true);
                        }
                        $selector.append(option);
                    });
                    $selector.prop('disabled', false);
                }
            });
        });
        $('.js-dsf-reset').click(function() {
            $selector.children('option:not(:first-child)').remove();
        });
    };

    $.fn.dynamicPostalCodeForm = function (url) {
        var $selector = $(this);
        var $postal_code = $('.js-dsf-postalcode');
        var $prefecture = $('.js-dsf-prefectures');
        var $town = $('.js-dsf-address1');

        $selector.click(function() {
            var postal_code = $postal_code.val();
            $.ajax({
                url: url,
                data: {postal_code: postal_code},
                type: 'GET',
            }).done(function(data) {
                $prefecture.val(data.data.prefecture_id).trigger('change', data.data.municipality_id);
                $town.val(data.data.town_name);
            })
        });
    };
});
